import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ArtSite';


  get layoutAlignment(): string {  return this.authService.isLoggedIn ? 'stretch stretch' : 'center center'; }


  constructor(private authService: AuthService) {}
}
